@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #f4f4f4;
$gray-200: #d6d6d6;
$gray-300: #bcbcbc;
$gray-400: #a9a9a9;
$gray-500: #7c7c7c;
$gray-600: #575757;
$gray-700: #484848;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start theme-color-variables
$primary: #04D960;
$secondary: #4900EA; // #DCEC88
$success: #04D960;
$warning: #D63384;
$info: #dcec88;
$danger: #ff3661;
//$danger: #f54c65;
$light: #f4f4f4;
$dark: #3b3b3b;
$brand-color: #0098db;
$brand-color-300: #7fcbec;
$brand-color-100: #b2dff4;
$secondary-300: #baf6df;
$secondary-100: #d5faec;
$info-300: #edf5c3;
$info-100: #f4f9db;
// scss-docs-end theme-color-variables

// scss-docs-start color-variables
$blue: #0d6efd;
$indigo: #6610f2;
// $purple:  #6f42c1 ;
$pink: #d63384;
// $red:     #E02020 ;
$orange: #fd7e14;
// $yellow:  #F7B500 ;
// $green:   #6DD400 ;
$teal: #20c997;
// $cyan:    #B620E0 ;
$green: #3bd700;
$red: #f50000;
$purple: #6932ff;
$skyblue: #00c8ff;
$cyan: #c700e8;
$slateblue: #0094ff;
$yellow: #ffb200;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
);
// scss-docs-end colors-map

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'gray-100': $gray-100,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'white': $white,
  'brand-color': $brand-color,
  'brand-color-300': $brand-color-300,
  'brand-color-100': $brand-color-100,
  'secondary-300': $secondary-300,
  'secondary-100': $secondary-100,
  'info-300': $info-300,
  'info-100': $info-100,
  'gray-900': $gray-900,
  'gray-300': $gray-300,
  'gray-600': $gray-600,
  'gray-800': $gray-800,
  'gray-400': $gray-400,
);
// scss-docs-end theme-colors-map

$border-radius: .1rem;
$border-radius-sm: .1rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: .1rem;
$btn-border-radius-sm: $btn-border-radius; //* 0.85;
$btn-border-radius-lg: $btn-border-radius; //* 1.25;

$btn-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 0%;
$btn-hover-bg-tint-amount: 0%;
$btn-hover-border-shade-amount: 0%;
$btn-hover-border-tint-amount: 0%;
$btn-active-bg-shade-amount: 0%;
$btn-active-bg-tint-amount: 0%;
$btn-active-border-shade-amount: 0%;
$btn-active-border-tint-amount: 0%;

$min-contrast-ratio: 3.2;

$navbar-padding-y: 1rem;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: $border-radius;
$nav-link-disabled-color: $gray-200 !default;
$nav-link-color: $gray-300;
$nav-pills-border-radius: $btn-border-radius;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $brand-color-300;
$nav-link-hover-color: $gray-400;
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;

$nav-tabs-link-active-bg: $gray-400;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $gray-300; //$nav-tabs-link-active-bg;
$font-family-sans-serif: 'Poppins',
  sans-serif;
$font-size-base: 1.125rem; // this is 18px

$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$h1-font-size: $font-size-base * 3.6;
$h2-font-size: $font-size-base * 3.05;
$h3-font-size: $font-size-base * 2.3;
$h4-font-size: $font-size-base * 2.1;
$h5-font-size: $font-size-base * 1.6;
$h6-font-size: $font-size-base * 1.5;

$text-muted: $gray-300 !default;

$badge-font-size: $font-size-sm;
$badge-font-weight: 400;
$badge-color: $white;
$badge-padding-y: 0.25rem;
$badge-padding-x: 1.375rem;
$badge-border-radius: $btn-border-radius * 0.9;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$card-cap-bg: transparent;
$card-spacer-y: $spacer !default;
$card-spacer-x: $spacer !default;
$card-border-radius: $border-radius;
$card-border-width: 0;
$card-inner-border-radius: $border-radius;

$card-title-spacer-y: 0rem;

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;
// scss-docs-end headings-variables

$grid-gutter-width: 1.875rem;

$box-shadow: 0 0.313rem 0.625rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.5rem rgba($black, 0.075);
$box-shadow-lg: 0 0.938rem 1.563rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$popover-max-width: 500px !default;

@each $color,
$value in $theme-colors {
  .badge-outline-#{$color} {
    border: 1px solid $value;
    color: $value !important;
    background: transparent !important;
  }
}

$input-border-radius: $btn-border-radius;
$input-border-radius-sm: $btn-border-radius;
$input-border-radius-lg: $btn-border-radius;
$input-placeholder-color: $gray-600 !default;

$tooltip-bg: $gray-800;
$tooltip-max-width: 15rem;

$link-decoration: 'none';

$form-select-padding-y: 0.8rem !default;
$form-select-padding-x: 1.5rem !default;

$form-switch-focus-color: rgba($black, 0.25) !default;
;